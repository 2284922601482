<template>
  <div class="not-found">
    <h1>Page not found...</h1>
  </div>
</template>

<style scoped>
.not-found {
  width: 100%;
  height: 100px;
}

.not-found h1 {
  margin: auto;
  color: var(--text-color-strong);
}
</style>

<script>
export default {
  name: "NotFound",
}
</script>

